import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import './header.scss';

const Header = ({ siteTitle }) => (
  <header className="padding-section">
    <div className="row col-12 pr-0">
      <div className="col-xs-12 col-sm-10 offset-sm-1 pr-0">
        <nav className="menu" role="navigation" aria-label="Main">
          <ul className="d-flex">
            <li className="title flex-grow-1">
              <Link to="/">{siteTitle}</Link>
            </li>
            <li>
              <Link to="/projects">Projects</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
