import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulButton.scss';

// This component is called button but in reality it takes care only of navigation, so everything here is expected to be a link
const ContentfulButton = ({ button, hideWrapping }) => {
  return hideWrapping ? (
    <div className="ButtonContainer col-xs-12">
      <a className="Button" href={button.buttonUrl}>
        {button.buttonName}
      </a>
    </div>
  ) : (
    <section className="padding-section">
      <div className="ButtonContainer component-section cols-xs-12">
        <div className="button-padding-section">
          <a className="Button" href={button.buttonUrl}>
            {button.buttonName}
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContentfulButton;
