import React from 'react';

import ContentfulButton from '../components/ContentfulButton';
import ContentfulFullWidthParagraphWithTitle from '../components/ContentfulFullWidthParagraphWithTitle';
import ContentfulParagraphWithTitleAndSideImage from '../components/ContentfulParagraphWithTitleAndSideImage';
import ContentfulFullWidthPicture from '../components/ContentfulFullWidthPicture';
import ContentfulFullWidthPictureWithMargins from '../components/ContentfulFullWidthPictureWithMargins';
import ContentfulParagraph from '../components/ContentfulParagraph';
import ContentfulParagraphWithTitle from '../components/ContentfulParagraphWithTitle';
import ContentfulQuoteWithPicture from '../components/ContentfulQuoteWithPicture';
import ContentfulSelectedProjects from '../components/ContentfulSelectedProjects';
import ContentfulAllProjects from '../components/ContentfulAllProjects';
import ContentfulSteps from '../components/ContentfulSteps';
import ContentfulTwoPicturesCollage from '../components/ContentfulTwoPicturesCollage';
import ContentfulWhatILove from '../components/ContentfulWhatILove';

const PageContent = ({ pageContent }) => {
  return pageContent.map((component, index) => {
    const componentType = component.internal.type;
    switch (componentType) {
      case 'ContentfulButton':
        return (
          <ContentfulButton key={index} button={component}></ContentfulButton>
        );
      case 'ContentfulFullWidthParagraphWithTitle':
        return (
          <ContentfulFullWidthParagraphWithTitle
            key={index}
            content={component}
          ></ContentfulFullWidthParagraphWithTitle>
        );
      case 'ContentfulParagraphWithTitleAndSideImage':
        return (
          <ContentfulParagraphWithTitleAndSideImage
            key={index}
            content={component}
          ></ContentfulParagraphWithTitleAndSideImage>
        );
      case 'ContentfulFullWidthPicture':
        return (
          <ContentfulFullWidthPicture
            key={index}
            content={component}
          ></ContentfulFullWidthPicture>
        );
      case 'ContentfulFullWidthPictureWithMargins':
        return (
          <ContentfulFullWidthPictureWithMargins
            key={index}
            content={component}
          ></ContentfulFullWidthPictureWithMargins>
        );
      case 'ContentfulParagraph':
        return (
          <ContentfulParagraph
            key={index}
            content={component}
          ></ContentfulParagraph>
        );
      case 'ContentfulParagraphWithTitle':
        return (
          <ContentfulParagraphWithTitle
            key={index}
            content={component}
          ></ContentfulParagraphWithTitle>
        );
      case 'ContentfulQuoteWithPicture':
        return (
          <ContentfulQuoteWithPicture
            key={index}
            content={component}
          ></ContentfulQuoteWithPicture>
        );
      case 'ContentfulSelectedProjects':
        return (
          <ContentfulSelectedProjects
            key={index}
            content={component}
          ></ContentfulSelectedProjects>
        );
      case 'ContentfulAllProjects':
        return (
          <ContentfulAllProjects
            key={index}
            content={component}
          ></ContentfulAllProjects>
        );
      case 'ContentfulSteps':
        return (
          <ContentfulSteps key={index} content={component}></ContentfulSteps>
        );
      case 'ContentfulTwoPicturesCollage':
        return (
          <ContentfulTwoPicturesCollage
            key={index}
            content={component}
          ></ContentfulTwoPicturesCollage>
        );
      case 'ContentfulWhatILove':
        return (
          <ContentfulWhatILove
            key={index}
            content={component}
          ></ContentfulWhatILove>
        );
      default:
        return null;
    }
  });
};

export default PageContent;
