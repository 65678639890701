import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulFullWidthPicture.scss';

const ContentfulFullWidthPicture = ({ content }) => {
  return (
    <section className="full-width-section">
      <div className="FullWidthPicture">
        <img
          className="FullWidthPicture"
          src={content.picture.file.url}
          alt={content.picture.title}
        ></img>
      </div>
    </section>
  );
};

export default ContentfulFullWidthPicture;
