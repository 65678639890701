import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulFullWidthPictureWithMargins.scss';

const ContentfulFullWidthPictureWithMargins = ({ content }) => {
  return (
    <section className="padding-section">
      <div className="FullWidthPictureWithMargins col-xs-12 col-sm-10 offset-sm-1 pb-0">
        <img src={content.picture.file.url} alt={content.picture.title}></img>
      </div>
    </section>
  );
};

export default ContentfulFullWidthPictureWithMargins;
