import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulWhatILove.scss';

const ContentfulWhatILove = ({ content }) => {
  return (
    <section className="full-width-section secondary-section padding-section">
      <div className="WhatILoveWrapper">
        <div className="WhatILove col-xs-12 col-sm-10 offset-sm-1">
          <h2 className="headline-2">What I love doing</h2>
          <div className="row">
            <div className="col-xs-12 col-sm-6 item-1">
              <img src={content.image1.file.url} alt={content.title1}></img>
              <h3 className="headline-4">{content.title1}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.description1.childMarkdownRemark.html,
                }}
              ></div>
            </div>
            <div className="col-xs-12 col-sm-6 item-2">
              <img src={content.image2.file.url} alt={content.title2}></img>
              <h3 className="headline-4">{content.title2}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.description2.childMarkdownRemark.html,
                }}
              ></div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-5"></div>
            <div className="col-xs-12 col-sm-6 col-md-6 item-3">
              <img src={content.image3.file.url} alt={content.title3}></img>
              <h3 className="headline-4">{content.title3}</h3>
              <div
                dangerouslySetInnerHTML={{
                  __html: content.description3.childMarkdownRemark.html,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentfulWhatILove;
