import React from 'react';
import ProjectsGrid from '../components/projectsGrid';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulSelectedProjects.scss';

const ContentfulSelectedProjects = ({ content }) => {
  return (
    <section className="padding-section">
      <div className="SelectedProjects col-xs-12 col-sm-10 offset-sm-1">
        <h2 className="headline-2">{content.title}</h2>
        <ProjectsGrid
          projects={content.projects}
          showAllProjects={content.showSeeAllProjects}
        />
      </div>
    </section>
  );
};

export default ContentfulSelectedProjects;
