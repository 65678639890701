import React from 'react';
import './ContentfulTwoPicturesCollage.scss';
import '../scss/grid.scss';
import './styles.scss';

const ContentfulTwoPicturesCollage = ({ content }) => {
  return (
    <section className="vertical-padding">
      <div className="PicturesCollage row col-12">
        <div className="col-xs-10 col-sm-6 align-self-center p-0">
          <div>
            <img
              src={content.pictureOne.file.url}
              alt={content.pictureOne.description}
            ></img>
          </div>
        </div>
        <div className="offset-xs-3 col-xs-9 col-sm-6 align-self-center align-items-right p-0 pl-4">
          <div>
            <img
              src={content.pictureTwo.file.url}
              alt={content.pictureTwo.description}
            ></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentfulTwoPicturesCollage;
