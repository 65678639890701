import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulFullWidthParagraphWithTitle.scss';

const ContentfulFullWidthParagraphWithTitle = ({ content }) => {
  return (
    <section className="padding-section">
      <div className="FullWidthParagraphWithTitle row col-12">
        <div className="offset-md-1 col-xs-10 col-md-7">
          {content.showParagraphTitle === 'Yes' ? (
            <h2 className="headline-1">{content.title}</h2>
          ) : (
            ''
          )}
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<p>' + content.paragraph.childMarkdownRemark.html + '</p>',
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default ContentfulFullWidthParagraphWithTitle;
