import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulParagraphWithTitle.scss';

const ContentfulParagraphWithTitle = ({ content }) => {
  return (
    <section className="padding-section">
      <div className="ParagraphWithTitle row col-12 pr-0">
        <div className="offset-md-1 col-xs-12 col-md-4">
          <h2 className="headline-2">{content.title}</h2>
        </div>
        <div className="col-xs-12 col-md-6 pr-0 pr-md-1">
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<p>' + content.paragraph.childMarkdownRemark.html + '</p>',
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default ContentfulParagraphWithTitle;
