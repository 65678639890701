import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulQuoteWithPicture.scss';

const ContentfulQuoteWithPicture = ({ content }) => {
  return (
    <div className="QuoteWithPicture component-section">QuoteWithPicture</div>
  );
};

export default ContentfulQuoteWithPicture;
