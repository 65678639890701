import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulParagraphWithTitleAndSideImage.scss';

const ContentfulParagraphWithTitleAndSideImage = ({ content }) => {
  return (
    <section className="padding-section">
      <div className="ParagraphWithTitleAndSideImage col-xs-12 col-sm-10 col-lg-10 offset-sm-1">
        <div className="row">
          <h1 className="headline-1 col-xs-12 col-sm-6">{content.title}</h1>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 order-sm-2 offset-sm-1 pr-sm-0">
            <img
              src={content.picture.file.url}
              alt={content.picture.description}
            />
          </div>
          <div
            className="col-xs-12 col-sm-5 order-sm-1"
            dangerouslySetInnerHTML={{
              __html:
                '<p>' + content.paragraph.childMarkdownRemark.html + '</p>',
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default ContentfulParagraphWithTitleAndSideImage;
