import React from 'react';
import ProjectsGrid from '../components/projectsGrid';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulAllProjects.scss';

const ContentfulAllProjects = ({ content }) => {
  return (
    <section className="padding-section">
      <div className="AllProjects col-xs-12 col-sm-10 col-lg-10 offset-sm-1">
        <div className="row col-xs-12 offset-sm-6 col-sm-6">
          <h1 className="headline-1">Selected projects</h1>
        </div>

        <ProjectsGrid projects={content.projects} />
      </div>
    </section>
  );
};

export default ContentfulAllProjects;
