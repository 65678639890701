import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulParagraph.scss';

const ContentfulParagraph = ({ content }) => {
  return (
    <section className="padding-section">
      <div className="Paragraph row col-12 pr-0">
        {/* <div className="col-xs-12 col-sm-3"></div> */}
        <div className="offset-md-5 col-xs-12 col-md-6">
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<p>' + content.paragraph.childMarkdownRemark.html + '</p>',
            }}
          ></div>
        </div>
      </div>
    </section>
  );
};

export default ContentfulParagraph;
