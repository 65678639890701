import React from 'react';
import '../scss/grid.scss';
import './styles.scss';
import './ContentfulStickyTitle.scss';

const ContentfulStickyTitle = ({ title }) => {
  return (
    <div className="StickyTitle">
      <div className="StickyContainer col-xs-12">
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default ContentfulStickyTitle;
