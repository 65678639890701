import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import './footer.scss';

const Footer = ({ siteTitle }) => (
  <footer className="padding-section">
    <div className="row col-12">
      <div className=" col-xs-12 col-sm-10 offset-sm-1">
        <nav className="footer-menu" role="navigation" aria-label="Footer">
          <ul className="d-md-flex">
            <li>
              <Link to="/" className="headline-3">
                {siteTitle}
              </Link>
            </li>
            <li>
              <Link to="/projects" className="headline-3">
                Projects
              </Link>
            </li>
            <li>
              <Link to="/contact" className="headline-3">
                Contact
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  siteTitle: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
};

export default Footer;
