import React from 'react';
import { Link } from 'gatsby';
import '../scss/grid.scss';
import './styles.scss';
import './projectsGrid.scss';
import ContentfulButton from '../components/ContentfulButton';

const ProjectsGrid = ({ projects, showAllProjects }) => {
  const button = { buttonName: 'See all projects', buttonUrl: 'projects' };
  return (
    <>
      <div className="projectsGrid row">
        {projects.map(project => (
          <div key={project.slug} className="col-xs-12 col-sm-6 linkbox">
            <Link
              to={`/projects/${project.slug}`}
              rel={project.projectTitle}
              title={`${project.projectTitle} - ${project.shortDescription.shortDescription}`}
            >
              <img
                src={project.projectImage.file.url}
                alt={project.projectImage.title}
              ></img>
              <h3 className="headline-4">{project.projectTitle}</h3>
              <p>{project.shortDescription.shortDescription}</p>{' '}
            </Link>
          </div>
        ))}
      </div>
      {showAllProjects ? (
        <ContentfulButton
          button={button}
          hideWrapping={true}
        ></ContentfulButton>
      ) : (
        ''
      )}
    </>
  );
};

export default ProjectsGrid;
